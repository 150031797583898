import React from "react";
import Benefits from "../Benefits/index.jsx";
import './WhyUs.scss'

/* descriptions */
const BENEFITS_FIRST_DESCR = 'Мы предоставляем гарантию на наши работы до 7 лет. Заказчики уверены в надежности оборудования и технических решениях наших специалистов.';

const BENEFITS_SECOND_DESCR = 'Звонок от заказчика самый важный. Для Вас мы каждый день на связи. И мы всегда отвечаем на звонок.';

const BENEFITS_THIRD_DESCR = 'Гибкий график платежей позволяет разделить оплаты на несколько этапов по желанию заказчика.';

const BENEFITS_FOURTH_DESCR = 'Мы полны бюджетом внимания и индивидуально подходим к каждому заказчику. Такой метод позволяет нам решать задачи любой сложности в короткие сроки.';

const BENEFITS_FIFTH_DESCR = 'Мы сотрудничаем только с лучшими и проверенными годами производителями оборудования. С нами Вы можете быть уверены в будущем.';

const BENEFITS_SIXTH_DESCR = 'Все наши специалисты имеют опыт работы более 5 лет в своей области. Они используют свои знания, чтобы сделать жизнь наших заказчиков лучше.';

const WhyUs = () => {
  return (
    <div className="container" id="whyUs">
      <span className="title">Мы спросили наших клиентов, почему они выбрали нас:</span>

      <div className="benefits-grid">
        <Benefits title='Гарантия' descr={BENEFITS_FIRST_DESCR} />
        <Benefits title='Поддержка' descr={BENEFITS_SECOND_DESCR} />
        <Benefits title='Комфортная оплата' descr={BENEFITS_THIRD_DESCR} />
        <Benefits title='Индивидуальный подохд' descr={BENEFITS_FOURTH_DESCR} />
        <Benefits title='Лучшее качество' descr={BENEFITS_FIFTH_DESCR} />
        <Benefits title='Опыт' descr={BENEFITS_SIXTH_DESCR} />
      </div>
      
    </div>
  );
};

export default WhyUs;
