import React from "react";
import { Rules } from "./Rules";
import "./ImportantRules.scss";

// img
import first from "../../resources/img/number1.svg";
import second from "../../resources/img/number2.svg";
import third from "../../resources/img/number3.svg";
import fourth from "../../resources/img/number4.svg";

/* descriptions */
const RULES_FIRST_DESCRIPTION =
  "Воздуховоды системы вентиляция достаточно объемные и не редко потолок приходится опускать для их монтажа. В связи с этим еще на этапе проектирования дома нужно учитывать это в расчете чистовой высоты потолка.";

const RULES_SECOND_DESCRIPTION =
  "Некоторые элементы системы вентиляции, например трубы до распределительных коробов, большие и не имеют возможности гнуться. В случае пересечения с другими коммуникациями могут возникнуть сложности. Поэтому первой нужно монтировать именно вентиляцию. Отопление и электрика идут следом.";

const RULES_THIRD_DESCRIPTION =
  "Монтаж системы вентиляции делится на 2 этапа. Сначала идут скрытые работы, когда производится установка всех труб, воздуховодов и распределительных коробов.";

const RULES_FOURTH_DESCRIPTION =
  "На 2 этапе к воздуховодам подключается сама установка. Её монтаж производится, когда в помещении, где она будет находиться, готова чистовая отделка. В этот момент производится пуско-наладка системы вентиляции.";

export const ImportantRules = () => {
  return (
    <div className="container">
      <span className="title">Важно знать:</span>

      <div className="rules-block">
        <Rules
          image={first}
          title="Учитывать монтаж вентиляции при проектировании дома"
          description={RULES_FIRST_DESCRIPTION}
        />

        <Rules
          image={second}
          title="Монтаж выполняем первым после коробки"
          description={RULES_SECOND_DESCRIPTION}
        />

        <Rules
          image={third}
          title="Скрытые работы"
          description={RULES_THIRD_DESCRIPTION}
        />

        <Rules
          image={fourth}
          title="Финишные работы"
          description={RULES_FOURTH_DESCRIPTION}
        />
      </div>
    </div>
  );
};
