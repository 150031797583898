import React from "react";
import { InfoServices } from "./InfoServices";
import "./Info.scss";

//img
import drill from "../../resources/img/drill.svg";
import planning from "../../resources/img/planning.svg";
import service from "../../resources/img/service.svg";

const infoParagraph =
  "Цель Атмосферы — поднятие качества наших услуг на новый уровень: мы планируем стать флагманом рынка. Наш опыт в проектировании и монтаже систем вентиляции и кондиционирования под ключ составляет более 12 лет. Мы стараемся найти индивидуальный подход к каждому объекту и, учитывая нюансы дизайна, максимально интегрируем системы. В Атмосфере мы предлагаем гарантию на работы и материалы, а также на устанавливаемое оборудование. У нас оптимальные цены за счёт грамотно выстроенных бизнес-процессов. Кроме этого, мы предлагаем сервисное и постгарантийное обслуживание.";

const infoDescrDrill = `Мы профессионально выполняем монтаж приточно-вытяжных систем вентиляции с рекуперацией тепла и увлажнения. И специализируемся только в этой области.`;
const infoDescrPlan = `Любую инженерную систему необходимо проектировать с учетом особенностей помещения. Наши проектировщики прошли обучение у производителей.`;
const infoDescrService = `Вентиляцию и увлажнение нужно обслуживать для её правильной и эффективной работы. Мы оказываем такую услугу. Замену фильтров можно сделать самостоятельно.`;

export const Info = () => {
  return (
    <div className="container">
      <div className="info info__content" id="aboutUs">
        <span className="title">О нас</span>

        <p>{infoParagraph}</p>
      </div>

      <p className="type-of-works">Виды предоставляемых нами работ:</p>
      <div className="info-services">
        <InfoServices
          image={drill}
          title="Монтаж"
          description={infoDescrDrill}
        />

        <InfoServices
          image={planning}
          title="Проектирование"
          description={infoDescrPlan}
        />

        <InfoServices
          image={service}
          title="Сервис"
          description={infoDescrService}
        />
      </div>

      {/* <button className="info__button">
            Связаться с нами
        </button> */}
    </div>
  );
};
