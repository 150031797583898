import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import "./Map.scss";

export default function YandexMap() {
  const defaultState = {
    center: [55.755864, 37.617698],
    zoom: 10,
  };

  return (
    <div className="container">
      <div className="map">
        <YMaps>
          <Map defaultState={defaultState} width="800px" height="400px">
            <Placemark geometry={[55.755864, 37.617698]} />
          </Map>
        </YMaps>
      </div>
    </div>
  );
}
