import React from 'react'
import './Popup.scss'

const Popup = ({ active, setActive, children }) => {
  return (
    <div 
        tabIndex={0}
        className={active ?  'popup active' : 'popup'} 
        onClick={() => setActive(false)}
        onKeyDown={e => e.key === 'Escape' && setActive(false)}
    >

        <div 
            className={active ?  'popup__content active' : 'popup__content'} 
            onClick={e => e.stopPropagation()}
        >
            {children}
        </div>
    </div>
  )
}

export default Popup