import React from "react";
import "./Footer.scss";
import logo from "../../resources/img/logo.svg";

export const Footer = () => {
  return (
    <div className="footer">
      <img className="footer__logo" src={logo} alt="Logotype" />

      <div className="contacts">
        <div className="contacts__email">
          Наша почта: <span>atmoclimat@yandex.ru</span>
        </div>
      </div>
    </div>
  );
};
