import { createContext, useState } from "react";
import CleanAirBenefits from "./components/CleanAirBenefits";
import OurWorks from "./components/OurWorks";
import ScopeOfApplications from "./components/ScopeOfApplications";
import WhyUs from "./components/WhyUs";
import Calculator from "./components/Calculator";
import { Header } from "./components/Header";
import { Info } from "./components/Info";
import { Footer } from "./components/Footer";
import { ImportantRules } from "./components/ImportantRules";
import { Contacts } from "./components/Сontacts";
import VentilationBenefits from "./components/VentilationBenefits";
// import Popup from "./components/Popup";
import Popup from "./components/Popup";
import { Navbar } from "./components/Navbar";
import { NavList } from "./components/Navbar/NavList";
import OurWorksTwo from "./components/OurWorksTwo";

export const AppContext = createContext("");

function App() {
  const [popupActive, setPopupActive] = useState(false);
  const [activeCard, setActiveCard] = useState("");
  const [activeNav, setActiveNav] = useState(false);

  return (
    <AppContext.Provider
      value={{
        popupActive,
        setPopupActive,
        activeCard,
        setActiveCard,
        activeNav,
        setActiveNav,
      }}
    >
      <div className="App">
        <Header>
          <Navbar active={activeNav} setActive={setActiveNav}>
            <NavList active={activeNav} setActive={setActiveNav} />
          </Navbar>
        </Header>
        <Popup active={popupActive} setActive={setPopupActive}>
          <Contacts />
        </Popup>
        <Info />
        <CleanAirBenefits />
        <VentilationBenefits />
        <WhyUs />
        <OurWorksTwo />
        <OurWorks />
        {/* <Popup active={popupActive} setActive={setPopupActive}>
          <h3 className="popup__title">
            {activeCard === 1 && "Информация о жилом комплексе «Level»"}
            {activeCard === 2 && "Информация о жилом комплексе «Монарх»"}
            {activeCard === 3 && "Информация о жилом комплексе «Титул»"}
            {activeCard === 4 && "Информация о жилом комплексе «Silver»"}
            {activeCard === 5 && "Информация о жилом комплексе «Династия»"}
            {activeCard === 6 && "Информация о жилом комплексе «Символ»"}
            {activeCard === 7 && "Информация о жилом комплексе «Квартал»"}
          </h3>

          <p className="popup__descr">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque,
            aut. Aperiam natus obcaecati non nostrum illo nobis assumenda magnam
            accusamus distinctio, placeat commodi nulla corporis beatae totam
            odio tenetur possimus harum dolore hic veritatis iusto optio!
            Possimus sequi blanditiis odio sit labore aspernatur fugit facere
            nesciunt! Sed voluptas temporibus corporis? Nostrum quibusdam quas,
            cupiditate neque deleniti sint quae temporibus. Aliquam, repudiandae
            assumenda! Velit tempore aut, illo dolor amet quis dolore vero quia
            eaque aperiam consequuntur error officia beatae eum ad rem accusamus
            vel molestiae. Non eius saepe eaque assumenda consequatur commodi,
            dolores ipsum cum amet veritatis, vitae natus ut laborum.
          </p>

          <p className="popup__descr">
            *Можно добавить изображения и любой другой текст или иконки*
          </p>
        </Popup> */}
        <ImportantRules />
        <ScopeOfApplications />

        <Calculator />
        <Footer />
      </div>
    </AppContext.Provider>
  );
}

export default App;
