import React from "react";
import "./PlacesOfWork.scss";
import OurWorksCard from "./PlacesOfWorkCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/* img */
import RCLevel from "../../resources/img/RCLevel.png";
import RCMonarch from "../../resources/img/RCMonarch.jpg";
import RCTitul from "../../resources/img/RCTitul.jpg";
import RCSilver from "../../resources/img/RCSilver.jpg";
import RCDynasty from "../../resources/img/RCDynasty.jpg";
import RCSymbol from "../../resources/img/RCSymbol.jpg";
import RCKvartal from "../../resources/img/RCKvartal.png";
import YandexMap from "./YandexMap";

/* static text */
// const OUR_WORKS_DESCR = 'Жилой комплекс «Level» на Амурской.'
const ADDITIONAL_INFO_TEXT = "Нажмите для дополнительной информации...";

const PlacesOfWork = () => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container" id="ourWorks">
      <span className="title">Где мы работаем:</span>

      <Slider className="our-works-container" {...settings}>
        <OurWorksCard
          img={RCLevel}
          title={"ЖК «Level»"}
          descr={"Жилой комплекс «Level»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={1}
        />

        <OurWorksCard
          img={RCMonarch}
          title={"ЖК «Монарх»"}
          descr={"Жилой комплекс «Монарх»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={2}
        />

        <OurWorksCard
          img={RCTitul}
          title={"ЖК «Титул»"}
          descr={"Жилой комплекс «Титул»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={3}
        />

        <OurWorksCard
          img={RCSilver}
          title={"ЖК «Silver»"}
          descr={"Жилой комплекс «Silver»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={4}
        />

        <OurWorksCard
          img={RCDynasty}
          title={"ЖК «Династия»"}
          descr={"Жилой комплекс «Династия»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={5}
        />

        <OurWorksCard
          img={RCSymbol}
          title={"ЖК «Символ»"}
          descr={"Жилой комплекс «Символ»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={6}
        />

        <OurWorksCard
          img={RCKvartal}
          title={"ЖК «Квартал»"}
          descr={"Жилой комплекс «Квартал»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={7}
        />
      </Slider>
      <YandexMap />
    </div>
  );
};

export default PlacesOfWork;
