import React /* , { useContext } */ from "react";
import "./PlacesOfWorkCard.scss";
// import { AppContext } from "../../../App";

const OurWorksCard = (props) => {
  /* const { setPopupActive } = useContext(AppContext);
  const { setActiveCard } = useContext(AppContext); */

  /*   const handleCardClick = () => {
    setPopupActive(true);
    setActiveCard(props.cardId);
  }; */

  return (
    <div>
      <div className="our-works-card" /* onClick={handleCardClick} */>
        <div>
          <img
            className="our-works-card__img"
            src={props.img}
            alt={props.title}
          />
        </div>

        <div className="our-works-card__title">
          <p>{props.title}</p>
        </div>

        {/* <div className="our-works-card__descr">
          <p>{props.descr}</p>

          <p>{props.additionalInfo}</p>
        </div> */}
      </div>
    </div>
  );
};

export default OurWorksCard;
