import React from "react";
import "./CleanAirBenefitLeft.scss";

const CleanAirBenefitLeft = (props) => {
  return (
    <div className="cl-air-benefit-left">
      <div>
        <img
          className="cl-air-benefits__img"
          src={props.img}
          alt="Clean Air Benefits"
        />
      </div>

      <div className="cl-air-benefits__info">
        <div className="cl-air-benefits__title">{props.title}</div>
        <div className="cl-air-benefits__descr">{props.descr}</div>
      </div>
    </div>
  );
};

export default CleanAirBenefitLeft;
