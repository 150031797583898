import axios from "axios";
import React, { useState } from "react";
import "./Form.scss";

export const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);

    const re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (!re.test(String(event.target.value).toLowerCase())) {
      setEmailMessage("Некорректный Email адрес");
    } else {
      setEmailMessage(null);
    }
  };

  const handleChangeMessage = (event) => {
    setUserMessage(event.target.value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    let emailValidation;

    if (!re.test(String(email).toLowerCase())) {
      emailValidation = false;
    } else {
      emailValidation = true;
    }

    let data = {
      emailValidation,
      email,
      name,
      message: userMessage,
    };

    const response = await axios
      .post("http://localhost:3001/api/sendemail", data)
      .then(() => alert("Заявка отправлена успешно"))
      .catch(() => alert("Упс, ошибка, что-то пошло не так ;("));
    console.log(response.data);
  };

  return (
    <div>
      <form
        action="#"
        className="form"
        method="POST"
        encType="multipart/form-data"
        onSubmit={sendEmail}
      >
        <input
          className="form__name-input"
          type="text"
          id="name"
          onChange={handleChangeName}
          placeholder="Введите имя"
          required
        />

        <p className="email-error">{email.length > 0 && emailMessage}</p>
        <input
          className="form__email-input"
          type="email"
          id="email"
          onChange={handleChangeEmail}
          placeholder="Введите email"
          required
        />

        <textarea
          className="form__textarea"
          id="message"
          onChange={handleChangeMessage}
          cols="60"
          rows="4"
          placeholder="Введите ваше сообщение"
          required
        ></textarea>

        <button type="submit" className="form__submit-btn">
          Отправить
        </button>
      </form>
    </div>
  );
};
