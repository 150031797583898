import React from "react";
import CleanAirBenefitLeft from "./CleanAirBenefitLeft";
import CleanAirBenefitRight from "./CleanAirBenefitRight";
import "./CleanAirBenefits.scss";

// img
import carbon from "../../resources/img/benefitsCarbon.png";
import health from "../../resources/img/benefitsHealth.png";
import mold from "../../resources/img/benefitsMold.png";
import repair from "../../resources/img/benefitsRepair.png";

// title and descr
const titleCarbon = "Высокий уровень углекислого газа понижает энергичность";
const descrCarbon =
  "При дыхании мы выделяем углекислый газ, большая концентрация которого очень негативно влияет на наш организм. Без кислорода мы начинаем чувствовать усталость, возможны головные боли, а также превышение углекислого газа сказывается на качестве сна.";

const titleHealth = "Загрязняющие вещества сказываются на здоровье";
const descrHealth =
  "Окись углерода, сажа, углеводороды, оксиды азота попадают в наши дома, квартиры и офисы через окна или общую вентиляцию. На людей, склонных к аллергии особенно влияют пыльца и пыль. Такие загрязнения серьезно влияют на здоровье и могут вызывать кашель, воспаление дыхательной системы, одышку и снижение работоспособности. В худшем случает эти вещества повышают риск сердечно-сосудистых заболеваний.";

const titleMold = "Плесень разрушает здания";
const descrMold =
  "Многие процессы нашей жизнедеятельности связаны с выделением влаги. Мы принимаем душ, готовим еду, сушим одежду и тд. При повышении влажности до 80% плесень начинает активно размножаться. Плесневые споры вредят не только нашему здоровью, но и разрушают конструкции здания. Считается, что повышенная влажность - это основная причина разрушения зданий.";

const titleRepair = "Вредные выбросы от ремонта и мебели вредят здоровью";
const descrRepair =
  "Летучие органические соединения (ЛОС), которые выделяют клей от обоев, краска, другие отделочные материалы и мебель, в течение более года после ремонта негативно влияют на наше здоровье. Они могу вызвать кашель, хрипоту, астму или бронхит. Мы не чувствуем их наличия в воздухе, но без вентиляции дышим ими и получаем вред своему здоровью.";
  
const CleanAirBenefits = () => {
  return (
    <div className="container cl-air-container">
      <span className="title">Почему свежий и чистый воздух так важен?</span>

      <div className="cl-air-all-benefits">
        <CleanAirBenefitLeft
          img={carbon}
          title={titleCarbon}
          descr={descrCarbon}
        />
        <CleanAirBenefitRight
          img={health}
          title={titleHealth}
          descr={descrHealth}
        />
        <CleanAirBenefitLeft img={mold} title={titleMold} descr={descrMold} />
        <CleanAirBenefitRight
          img={repair}
          title={titleRepair}
          descr={descrRepair}
        />
      </div>
    </div>
  );
};

export default CleanAirBenefits;
