import React from "react";
import "./OurWorks2.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/* img */
import photo_1 from "../../resources/img/photo_1.jpg";
import photo_2 from "../../resources/img/photo_2.jpg";
import photo_3 from "../../resources/img/photo_3.jpg";
import photo_4 from "../../resources/img/photo_4.jpg";
import photo_5 from "../../resources/img/photo_5.jpg";
import photo_6 from "../../resources/img/photo_6.jpg";
import photo_7 from "../../resources/img/photo_7.jpg";
import photo_8 from "../../resources/img/photo_8.jpg";
import photo_9 from "../../resources/img/photo_9.jpg";
import photo_10 from "../../resources/img/photo_10.jpg";
import photo_11 from "../../resources/img/photo_11.jpg";
import photo_12 from "../../resources/img/photo_12.jpg";
import photo_13 from "../../resources/img/photo_13.jpg";
import photo_14 from "../../resources/img/photo_14.jpg";
import photo_15 from "../../resources/img/photo_15.jpg";
import photo_16 from "../../resources/img/photo_16.jpg";
import photo_17 from "../../resources/img/photo_17.jpg";
import photo_18 from "../../resources/img/photo_18.jpg";
import photo_19 from "../../resources/img/photo_19.jpg";
import photo_20 from "../../resources/img/photo_20.jpg";
import photo_21 from "../../resources/img/photo_21.jpg";
import photo_22 from "../../resources/img/photo_22.jpg";
import photo_23 from "../../resources/img/photo_23.jpg";
import photo_24 from "../../resources/img/photo_24.jpg";
import photo_25 from "../../resources/img/photo_25.jpg";
import photo_26 from "../../resources/img/photo_26.jpg";
import photo_27 from "../../resources/img/photo_27.jpg";
import photo_28 from "../../resources/img/photo_28.jpg";

/* static text */
// const OUR_WORKS_DESCR = 'Жилой комплекс «Level» на Амурской.'

const OurWorksTwo = () => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container" id="ourWorks">
      <span className="title">Наши работы:</span>

      <Slider className="our-works-container1" {...settings}>
        <img src={photo_1} alt="" className="img" />
        <img src={photo_2} alt="" className="img" />
        <img src={photo_3} alt="" className="img" />
        <img src={photo_4} alt="" className="img" />
        <img src={photo_5} alt="" className="img" />
        <img src={photo_6} alt="" className="img" />
        <img src={photo_7} alt="" className="img" />
        <img src={photo_8} alt="" className="img" />
        <img src={photo_9} alt="" className="img" />
        <img src={photo_10} alt="" className="img" />
        <img src={photo_11} alt="" className="img" />
        <img src={photo_12} alt="" className="img" />
        <img src={photo_13} alt="" className="img" />
        <img src={photo_14} alt="" className="img" />
        <img src={photo_15} alt="" className="img" />
        <img src={photo_16} alt="" className="img" />
        <img src={photo_17} alt="" className="img" />
        <img src={photo_18} alt="" className="img" />
        <img src={photo_19} alt="" className="img" />
        <img src={photo_20} alt="" className="img" />
        <img src={photo_21} alt="" className="img" />
        <img src={photo_22} alt="" className="img" />
        <img src={photo_23} alt="" className="img" />
        <img src={photo_24} alt="" className="img" />
        <img src={photo_25} alt="" className="img" />
        <img src={photo_26} alt="" className="img" />
        <img src={photo_27} alt="" className="img" />
        <img src={photo_28} alt="" className="img" />

        {/* <OurWorksCard
          img={RCLevel}
          title={"ЖК «Level»"}
          descr={"Жилой комплекс «Level»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={1}
        />

        <OurWorksCard
          img={RCMonarch}
          title={"ЖК «Монарх»"}
          descr={"Жилой комплекс «Монарх»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={2}
        />

        <OurWorksCard
          img={RCTitul}
          title={"ЖК «Титул»"}
          descr={"Жилой комплекс «Титул»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={3}
        />

        <OurWorksCard
          img={RCSilver}
          title={"ЖК «Silver»"}
          descr={"Жилой комплекс «Silver»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={4}
        />

        <OurWorksCard
          img={RCDynasty}
          title={"ЖК «Династия»"}
          descr={"Жилой комплекс «Династия»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={5}
        />

        <OurWorksCard
          img={RCSymbol}
          title={"ЖК «Символ»"}
          descr={"Жилой комплекс «Символ»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={6}
        />

        <OurWorksCard
          img={RCKvartal}
          title={"ЖК «Квартал»"}
          descr={"Жилой комплекс «Квартал»"}
          additionalInfo={ADDITIONAL_INFO_TEXT}
          cardId={7}
        /> */}
      </Slider>
    </div>
  );
};

export default OurWorksTwo;
