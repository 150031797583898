import React, { useContext } from "react";
import "./NavList.scss";
import { Link } from "react-scroll";
import { AppContext } from "../../../App";

export const NavList = () => {
  const { setPopupActive } = useContext(AppContext);
  const { activeNav, setActiveNav } = useContext(AppContext);

  const handleBtnClick = () => {
    setPopupActive(true);
  };

  return (
    <div className="nav-list">
      <li className="nav-list__item ">
        <Link
          to="aboutUs"
          spy={true}
          offset={-125}
          duration={500}
          className="nav-list__link "
          onClick={() => setActiveNav(!activeNav)}
        >
          О нас
        </Link>
      </li>

      <li className="nav-list__item ">
        <Link
          to="whyUs"
          spy={true}
          offset={-60}
          duration={500}
          className="nav-list__link "
          onClick={() => setActiveNav(!activeNav)}
        >
          Почему мы
        </Link>
      </li>

      <li className="nav-list__item ">
        <Link
          to="ourWorks"
          spy={true}
          offset={-33}
          duration={500}
          className="nav-list__link "
          onClick={() => setActiveNav(!activeNav)}
        >
          Партнёрам
        </Link>
      </li>

      <li className="nav-list__item ">
        <Link
          to="calculator"
          spy={true}
          offset={-28} // -160 с сообщением вместо калькулятора
          duration={500}
          className="nav-list__link "
          onClick={() => setActiveNav(!activeNav)}
        >
          Рассчитать стоимость
        </Link>
      </li>

      <li className="nav-list__item ">
        {/* <Link
          to="contacts"
          spy={true}
          offset={-50}
          duration={500}
          className="nav-list__link "
        >
          Связаться с нами
        </Link> */}
        <button className="nav-list__contact-us" onClick={handleBtnClick}>
          Связаться с нами
        </button>
      </li>
    </div>
  );
};
