import React from "react";
import { Form } from "./Form";
import "./Contacts.scss";

export const Contacts = () => {
  return (
    <div className="container" id="contacts">
        <span className="title">Напишите нам</span>

        <div className="contacts">
            <Form />
        </div>
    </div>
  );
};
