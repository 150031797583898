import React from "react";
import { Navbar } from "../Navbar";
import "./Header.scss";

const staticParagraph =
  "Компания Атмосфера успешно занимается созданием комфортного микроклимата в квартирах, домах, а также офисах под ключ. Миссия нашей компании — создание идеально комфортного климата для наших заказчиков.";

export const Header = () => {
  return (
      <div className="header">
        <Navbar />
        <div className="header__title">
        <p>{staticParagraph}</p>
        </div>
    </div>
  );
};
