import React, { useContext, useState } from "react";
import "./Navbar.scss";
import { NavList } from "./NavList";
import logo from "../../resources/img/logo.svg";
import logoBlack from "../../resources/img/logoBlack.svg";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { AppContext } from "../../App";

export const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const { activeNav, setActiveNav } = useContext(AppContext);

  const changeBackground = () => {
    if (window.scrollY >= window.innerHeight) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <nav className="nav">
      <div className={navbar ? "nav__row active" : "nav__row"}>
        <img
          className="nav__logo"
          src={navbar ? logo : logoBlack}
          alt="Logotype"
        />

        <ul className={activeNav ? "nav-menu active" : "nav-menu"}>
          <NavList />
        </ul>
        <div onClick={() => setActiveNav(!activeNav)}>
          {activeNav ? (
            <AiOutlineClose className="icon" />
          ) : (
            <AiOutlineMenu className="icon" />
          )}
        </div>
      </div>
    </nav>
  );
};
