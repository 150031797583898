import React, { useState } from "react";
import "./Calculator.scss";
import Select from "react-select";
import Popup from "../Popup";

const Calculator = () => {
  const options = [
    /* {value: 'house', label: 'Дом'},
        {value: 'apartments', label: 'Квартира'},
        {value: 'office', label: 'Офис'}, */
    { value: "ventilation", label: "Вентиляция" },
    {
      value: "ventilationWithAirConditioning",
      label: "Вентиляция и Кондиционирование",
    },
  ];

  const [value, setValue] = useState("");

  const handleChangeSelect = (selectedOption) => {
    setValue(selectedOption);
  };

  const [squareMeters, setSquareMeters] = useState("");

  const handleChangeSquareMeters = (event) => {
    setSquareMeters(event.target.value);
  };

  /* KITCHEN SQUARE */
  // const [kitchenSquareValue, setKitchenSquareValue] = useState(1);

  /* const handleChangeKitchenSquare = (e) => {
        setKitchenSquareValue(e.target.value);
    } */

  /* LIVING ROOM SQUARE */
  // const [livingRoomSquareValue, setLivingRoomSquareValue] = useState(1);

  /* const handleChangeLivingRoomSquare = (e) => {
        setLivingRoomSquareValue(e.target.value);
    } 
 */
  /* CEILING HEIGHT */
  const [ceilingHeight, setCeilingHeight] = useState("");

  const handleChangeCeilingHeight = (event) => {
    setCeilingHeight(event.target.value);
  };

  /* FLOORS */
  const [floorsCount, setFloorsCount] = useState("");

  const handleChangeFloorsCount = (event) => {
    setFloorsCount(event.target.value);
  };

  const handleClickFloorsNegative = () => {
    setFloorsCount((prevCount) => (prevCount < 2 ? prevCount : --prevCount));
  };

  const handleClickFloorsPlus = () => {
    setFloorsCount((prevCount) => ++prevCount);
  };

  /* BATHROOMS */
  // const [bathroomsCount, setBathroomsCount] = useState('');

  /* const handleChangeBathroomsCount = (event) => {
        setBathroomsCount(event.target.value);
    } */

  /* const handleClickBathroomsNegative = () => {
        setBathroomsCount((prevCount) => prevCount < 2 ? prevCount : --prevCount)
    }

    const handleClickBathroomsPlus = () => {
        setBathroomsCount((prevCount) => ++prevCount)
    } */

  /* BEDROOMS */
  /* const [bedroomsCount, setBedroomsCount] = useState('');

    const handleChangeBedroomsCount = (event) => {
        setBedroomsCount(event.target.value);
    }

    const handleClickBedroomsNegative = () => {
        setBedroomsCount((prevCount) => prevCount < 2 ? prevCount : --prevCount)
    }

    const handleClickBedroomsPlus = () => {
        setBedroomsCount((prevCount) => ++prevCount)
    } */

  /* PHONE NUMBER */
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberMessage, setPhoneNumberMessage] = useState("");

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);

    if (phoneNumber.length < 11) {
      setPhoneNumberMessage("Введите минимум 11 символов");
    }
  };

  /* EMAIL */
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);

    const re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (!re.test(String(event.target.value).toLowerCase())) {
      setEmailMessage("Некорректный Email адрес");
    } else {
      setEmailMessage(null);
    }
  };

  /* MESSAGE */
  // const [submitMessage, setSubmitMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  /* SUBMIT BTN */
  const [popupActive, setPopupActive] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [price, setPrice] = useState("");

  const handleClickPrice = (e) => {
    e.preventDefault();

    setSelectValue({ value }.value.label);

    const info = {
      workType: { value }.value.label,
      overallArea: squareMeters,
    };

    let emailValidation;

    const re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (!re.test(String(email).toLowerCase())) {
      emailValidation = false;
    } else {
      emailValidation = true;
    }

    setErrorMessage(
      info.workType && info.overallArea
        ? // info.ceilingsHeight &&
          // info.floors /* && info.bathrooms && info.bedrooms */ &&
          // info.userPhoheNumber.length >= 11 &&
          // emailValidation
          ""
        : "Пожалуйста, заполните все корректно!"
    );

    console.log(
      info.workType &&
        info.overallArea &&
        info.ceilingsHeight &&
        info.floors /* && info.bathrooms && info.bedrooms */ &&
        info.userPhoheNumber.length >= 11 &&
        emailValidation
        ? info
        : "Пожалуйста, заполните все поля корректно!"
    );

    // setFormSubmitStatus(
    //     info.workType && info.overallArea && info.ceilingsHeight && info.floors /* && info.bathrooms && info.bedrooms */ && info.userPhoheNumber.length >= 11 && emailValidation ? true : false
    // );

    // setSubmitMessage(
    //     info.workType && info.overallArea && info.ceilingsHeight && info.floors /* && info.bathrooms && info.bedrooms */ && info.userPhoheNumber.length >= 11 && emailValidation && 'Ваша заявка принята. Спасибо за то, что выбрали нас!'
    // );

    let PRICE_AIR_COND = 195; // in rubles
    let PRICE_AIR_COND_WITH_VENT = 255; // in rubles

    if ({ value }.value.label === "Вентиляция") {
      setPrice(squareMeters * PRICE_AIR_COND);
    } else if ({ value }.value.label === "Вентиляция и Кондиционирование") {
      setPrice(squareMeters * PRICE_AIR_COND_WITH_VENT);
    }

    setPopupActive(
      info.workType && info.overallArea
        ? // info.ceilingsHeight &&
          // info.floors /* && info.bathrooms && info.bedrooms */ &&
          // info.userPhoheNumber.length >= 11 &&
          // emailValidation
          true
        : false
    );
  };

  /* FORM SUBMIT STATUS */
  // const [formSubmitStatus, setFormSubmitStatus] = useState('');

  return (
    <>
      {/* {
                formSubmitStatus 
                ? (<p className='submit-message' id='calculator'>{submitMessage}</p>)
                : 
                ( */}
      <div className="container" id="calculator">
        <Popup active={popupActive} setActive={setPopupActive}>
          <p className="calc-popup-info">
            Тип работ: {selectValue}
            <br />
            Общая площадь: {squareMeters}м&sup2;
            {/* <br />
            Высота потолков: {ceilingHeight}м
            <br />
            Количество этажей: {floorsCount}
            <br />
            Номер вашего телефона: {phoneNumber}
            <br />
            Ваша электронная почта: {email}
            <br /> */}
            <span className="calc-popup-descr">
              Учитывая вышеуказанную Вами информацию, <br /> приблизительная
              стоимость проекта будет составлять{" "}
              <span className="calc-price">{price}</span> руб.
            </span>
          </p>

          {/* <p className="calc-popup-message">
                Мы стараемся ценить время своих клиентов и выполнять свою работу максимально качественно.
                <br />
                Спасибо, что обратились к нам!
                <br />
            </p> */}

          <p className="calc-popup-small-msg">
            Если у Вас остались вопросы или Вы хотите связаться с нами — Вы
            можете сделать это в конце сайта, где есть форма для завки и наши
            контакты.
          </p>
        </Popup>

        <span className="title">Рассчитать стоимость проекта</span>

        <div className="calculator">
          <form action="#" className="form" method="POST">
            <Select
              className="calculator__select"
              id="calculatorSelect"
              options={options}
              value={value}
              placeholder="Выберите тип работ"
              onChange={handleChangeSelect}
              required
              // isMulti

              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  // fontSize: "18px",
                  //   fontWeight: 700,
                  fontFamily: "rubik",
                  borderColor: "#a2a2a2",
                  cursor: "pointer",
                  padding: 5,
                  ":hover": {
                    borderColor: "#a2a2a2",
                  },
                }),
              }}
            />

            <input
              className="calculator__square-meters"
              type="number"
              id="squareMeters"
              placeholder="Введите общую площадь (м&sup2;)"
              onChange={handleChangeSquareMeters}
              value={squareMeters}
              min={1}
              required
            />

            <input
              type="submit"
              className="calculator__count-btn"
              onClick={handleClickPrice}
              value="Узнать стоимость работ"
            />

            <p className="submit-error-message">{errorMessage}</p>
          </form>
        </div>
      </div>
      {/* )
            } */}
    </>
  );
};

export default Calculator;
